<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">

            <!-- Login v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <vuexy-logo />

                    <h2 class="brand-text text-primary ml-1">
                        {{ appName }}
                    </h2>
                </b-link>

                <b-card-title class="mb-1">
                    {{ $t(`pages.${pagename}.welecomtopage`,{ appName: appName }) }}
                </b-card-title>
                <b-card-text class="mb-2">
                    {{ $t(`pages.${pagename}.msg1`) }}
                </b-card-text>

                <!-- form -->
                <validation-observer
                    ref="loginValidation"
                    #default="{invalid}"
                >
                    <b-form
                        class="auth-login-form mt-2"
                        @submit.prevent
                    >

                        <!-- account -->
                        <b-form-group
                            label-for="email"
                            :label="$t('common.accountoremail')"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Email"
                                rules="required"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="userEmail"
                                    tabindex="1"
                                    name="login-email"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="abc@example.com"
                                    autofocus
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group class="mt-1">
                            <div class="d-flex justify-content-between">
                                <label for="password">{{ $t('common.password') }}</label>
                                <b-link :to="{name:'ForgotPassword', params: {}}">
                                    <small>{{ $t(`pages.${pagename}.msg5`) }}</small>
                                </b-link>
                            </div>
                            <validation-provider
                                #default="{ errors }"
                                name="Password"
                                rules="required"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid':null"
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="password"
                                        tabindex="2"
                                        :type="passwordFieldType"
                                        class="form-control-merge"
                                        :state="errors.length > 0 ? false:null"
                                        name="login-password"
                                        placeholder="Password"
                                    />

                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="passwordToggleIcon"
                                            @click="togglePasswordVisibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- checkbox -->
                        <b-form-group>
                            <b-form-checkbox
                                id="remember-me"
                                v-model="status"
                                name="checkbox-1"
                            >
                                {{ $t(`pages.${pagename}.msg2`) }}
                            </b-form-checkbox>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button
                            variant="primary"
                            type="submit"
                            block
                            :disabled="invalid"
                            @click="validationForm"
                        >
                            {{ $t('common.signin') }}
                        </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <span>{{ $t(`pages.${pagename}.msg3`) }} </span>
                    <b-link :to="{name:'Register', params: {}}">
                        <span>{{ $t(`pages.${pagename}.msg4`) }}</span>
                    </b-link>
                </b-card-text>

                <!-- <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

                <!-- social button -->
                <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
            </b-card>
            <!-- /Login v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { accountAbility } from '@/libs/acl/config'
import { $themeConfig } from '@themeConfig'
import { Tientech_getPageRandomNumber } from '@/libs/tientech/api'

export default {
  setup() {
    // App Name
    const {
      appName, appLogoImage, companyName, companyUrl, author,
    } = $themeConfig.webConfigs[document.location.hostname] ? $themeConfig.webConfigs[document.location.hostname] : $themeConfig.app
    return {
      pagename: 'login',
      random_number: 0,
      appName,
      appLogoImage,
      companyName,
      companyUrl,
      author,
    }
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    try {
      // let vuethis = this
      this.initialize()
    } catch (error) {
      console.log(error)
    }
  },
  created() {
    this.random_number = Tientech_getPageRandomNumber()
  },
  methods: {
    initialize() {
      try {
        // initialize
      } catch (error) {
        console.log(error)
      }
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (!success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('error_common.input_error'),
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
        const account = this.userEmail
        const { password } = this

        const obj = {
          account,
          password,
        }
        // this.$socket.connect()
        this.$socket.emit('accountcloud_login', {
          data: obj,
          type: 'password',
          callback_params: `${this.pagename}_${this.random_number}`,
        }, params => {
          try {
            // console.log(params)
            if (params.type === 'success') {
              const role = params.data.level | 'visitor'
              let ability = []
              // if (params.data.level === 'admin') {
              ability = accountAbility[params.data.level]
              // }
              // Subscription, Article, User

              const objuserinfo = {
                userpn: params.userpn,
                dealer: params.data.dealer,
                enable: params.data.enable,
                level: params.data.level,
                account: params.data.account,
                name: params.data.name,
                fullName: params.data.name,
                username: params.data.name,
                role,
                ability,
                datetime: params.datetime,
              }
              localStorage.setItem('userData', JSON.stringify(objuserinfo))
              // localStorage.setItem('userToken', params.token)
              if (this.status) {
                this.$cookies.set('userData', encodeURIComponent(JSON.stringify(objuserinfo)), '100y')
                this.$cookies.set('userToken', params.token, '100y')
              } else {
                this.$cookies.set('userData', encodeURIComponent(JSON.stringify(objuserinfo)))
                this.$cookies.set('userToken', params.token)
              }
              useJwt.setToken(params.token)
              useJwt.setRefreshToken(params.token)
              this.$ability.update(objuserinfo.ability)

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('common.welecomuserlogin', { account }),
                  icon: 'UserIcon',
                  variant: 'success',
                },
              }, {
                position: 'top-center',
                timeout: 1000,
              })
              setTimeout(() => {
                // this.$router.push('/dashboard')
                this.$router.push({ name: 'dashboard' }).catch(error => {
                  // console.warn(error.message)
                })
              }, 1000)
            } else if (params.type === 'error') {
              let errmsg = ''
              if (params.msg === 'account_error_nouser') {
                errmsg = this.$t('error_common.account_error_nouser')
              } else if (params.msg === 'account_error_password') {
                errmsg = this.$t('error_common.account_error_userorpassword')
              } else if (params.msg === 'account_error_notenabled') {
                errmsg = this.$t('error_common.account_error_notenabled')
              } else {
                errmsg = params.msg
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errmsg,
                  icon: 'UserIcon',
                  variant: 'danger',
                },
              }, {
                position: 'top-center',
              })
            }
          } catch (error) {
            console.log(error)
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
